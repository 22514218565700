import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMobile, faTools } from "@fortawesome/free-solid-svg-icons"
import { faAppStoreIos } from "@fortawesome/free-brands-svg-icons"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero"
import Section from "../../components/section/section"


const Wrapper = styled.div`
    ${tw``}
`

const Container = styled.div`
    ${tw`container flex flex-wrap mx-auto text-center max-w-4xl`}
`

const CardWrapper = styled.div`
    ${tw`w-full md:w-1/2 lg:w-1/3 p-2`}
`

const CardContainer = styled.div`
    ${tw`container flex flex-col mx-auto text-center bg-gray-600 p-2 md:p-4 rounded-lg`}
`

const AccessoryStyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    ${tw`m-4 text-psb-green-600 text-4xl md:text-5xl self-center`}
`

const SoftwareStyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    ${tw`m-4 text-psb-orange-400 text-4xl md:text-5xl self-center`}
`

const HardwareStyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    ${tw`m-4 text-blue-400 text-4xl md:text-5xl self-center`}
`

const StyledLink = styled(Link)`
    ${tw`block mx-4 p-2 rounded-lg text-white text-lg font-bold`}
`

const AccessoryStyledLink = styled(StyledLink)`
    ${tw`bg-psb-green-600`}
`

const SoftwareStyledLink = styled(StyledLink)`
    ${tw`bg-psb-orange-400 content-end`}
`

const HardwareStyledLink = styled(StyledLink)`
    ${tw`bg-blue-400`}
`

const GuidesPage = ({ data, location }) => {

  	return (
    	<Layout>
        	<SEO
            	title="Pocket Shutterbug Guides"
	            pathname={location.pathname}
    	    />
			<Hero title="Guides" description="Our guides share the top recommendations across the most popular categories" image={data.hero.childImageSharp.fluid} />
        	<Section>
                <Wrapper>
                    <p>Welcome to the Pocket Shutterbug Guides. We will be adding more products and categories as we continue to bring you our unique and personal reviews and recommendations.</p>
                    <h2>Accessories</h2>
                    <p>Read on below for our top accessory recommendations.</p>
                    <Container>
                        <CardWrapper>
                            <CardContainer>
                                <AccessoryStyledFontAwesomeIcon icon={faTools} />
                                <h3>Tripods</h3>
                                <p/>
                                <AccessoryStyledLink to="/guides/tripods">Explore Tripods</AccessoryStyledLink>
                            </CardContainer>
                        </CardWrapper>
                        <CardWrapper>
                            <CardContainer>
                                <AccessoryStyledFontAwesomeIcon icon={faTools} />
                                <h3>Gimbals</h3>
                                <p/>
                                <AccessoryStyledLink to="/guides/">Coming soon...</AccessoryStyledLink>
                            </CardContainer>
                        </CardWrapper>
                    </Container>
                    <h2>Software</h2>
                    <p>Read on below for our top software recommendations.</p>
                    <Container>
                        <CardWrapper>
                            <CardContainer>
                                <SoftwareStyledFontAwesomeIcon icon={faAppStoreIos} />
                                <h3>Photo Capture</h3>
                                <p/>
                                <SoftwareStyledLink to="/guides/">Coming soon...</SoftwareStyledLink>
                            </CardContainer>
                        </CardWrapper>
                        <CardWrapper>
                            <CardContainer>
                                <SoftwareStyledFontAwesomeIcon icon={faAppStoreIos} />
                                <h3>Photo Editing</h3>
                                <p/>
                                <SoftwareStyledLink to="/guides/">Coming soon...</SoftwareStyledLink>
                            </CardContainer>
                        </CardWrapper>
                        <CardWrapper>
                            <CardContainer>
                                <SoftwareStyledFontAwesomeIcon icon={faAppStoreIos} />
                                <h3>Video Capture</h3>
                                <p/>
                                <SoftwareStyledLink to="/guides/">Coming soon...</SoftwareStyledLink>
                            </CardContainer>
                        </CardWrapper>
                        <CardWrapper>
                            <CardContainer>
                                <SoftwareStyledFontAwesomeIcon icon={faAppStoreIos} />
                                <h3>Video Editing</h3>
                                <p/>
                                <SoftwareStyledLink to="/guides/">Coming soon...</SoftwareStyledLink>
                            </CardContainer>
                        </CardWrapper>
                    </Container>
                    <h2>Hardware</h2>
                    <p>Read on below for our top hardware recommendations.</p>
                    <Container>
                        <CardWrapper>
                            <CardContainer>
                                <HardwareStyledFontAwesomeIcon icon={faMobile} />
                                <h3>Apple iPhone</h3>
                                <p/>
                                <HardwareStyledLink to="/guides/">Coming soon...</HardwareStyledLink>
                            </CardContainer>
                        </CardWrapper>
                    </Container>
                </Wrapper>
 	       </Section>
	    </Layout>
	)
}

export default GuidesPage

export const query=graphql`
    query {
        hero: file(relativePath: { eq: "mirage-teamwork_400.png" }) {
            childImageSharp {
                fluid(maxWidth: 400 quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`